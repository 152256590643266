import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery';

import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import { Logo } from '../index'
import { Iinfo } from '../../types/interface'
import { colors } from '../../colors/colors'
import { theme } from '../../App'
import { Divider, Typography } from '@mui/material';

interface Iprops {
    info:Iinfo
}
const Footer = (props:Iprops) => {
    const { info } = props
    const mobile = useMediaQuery("(max-width: 690px)")
    const navigate = useNavigate()
    return (
        <Box className="flex__center-c" sx={{width:"100%",minHeight:"10rem",backgroundColor: colors?.bg?.dark,p:"2rem 0"}}>

            <Box className="flex__center-r" width={"100%"}>
                <Box sx={{ backgroundColor:"#ffffff42", height:"1px", width:"90%",m:"0 2rem"}}/>
                <Logo height={5} fill={theme?.palette?.primary.main} navigateOn={true}/>
                <Box sx={{ backgroundColor:"#ffffff42", height:"1px", width:"90%",m:"0 2rem"}}/>
            </Box>



            <Box className="flex__center-r flex__r-c" m="3rem 0" width={"100%"} minHeight={"5rem"} sx={{justifyContent:"space-around"}}>

                {/* Info and buttons */}
                <Box className="flex__center-c" sx={{maxWidth: mobile ? "95%" : "50%", alignItems:"flex-start"}} >

                    <Typography variant='h2' color="primary.contrastText" className="flex__center-r" sx={{mr:"1rem",textAlign:"left"}}>
                        {info?.Footer?.Title}
                    </Typography>  
                    <Typography variant='subtitle1' color="primary.contrastText" className="flex__center-r" sx={{mr:"1rem",textAlign:"left"}}>
                        {info?.Footer?.Info}
                    </Typography>  

                    <Box mt={"0.5rem"} className="flex__center-c" sx={{alignItems:"flex-start"}}>
                    <Typography variant="subtitle1" onClick={()=>navigate("/contact")} sx={{mr:"0.5rem",color:"secondary.light",textAlign:"left",fontWeight:"500",cursor:'pointer'}} >
                            {info?.Footer?.Button_Contact}
                            </Typography>
                        <Typography variant="subtitle1" onClick={()=>navigate("/hire")} sx={{mr:"0.5rem",color:"secondary.light",textAlign:"left",fontWeight:"500",cursor:'pointer'}} >
                            {info?.Footer?.Button_Hire}
                            </Typography>
                        <Typography variant="subtitle1" onClick={()=>navigate("/policy")} sx={{mr:"0.5rem",color:"secondary.light",textAlign:"left",fontWeight:"500",cursor:'pointer'}} >
                            {info?.Footer?.Button_Policy}
                        </Typography>
                    </Box>



                </Box>

                {/* Address, email, phone */}
                <Box className="flex__center-c" sx={{maxWidth:mobile ? "95%" : "50%", width: mobile ? "100%" : "auto" ,alignItems:"flex-start", justifyContent:"flex-start" ,mt: mobile ? "2rem" : 0}}>

                        
                        <IconButton sx={{':hover':{backgroundColor:"#ffffff00"},p:0}} href={ info?.CompanyInfo?.Address_Link || "" } target="_blank" rel="noopener" className="flex__center-c" >
                            <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText" sx={{textAlign:"left",alignItems:"flex-start"}}>
                                <PlaceIcon sx={{fontSize:"1.5rem",mr:"0.5rem",color:"secondary.light"}} />
                                {info?.CompanyInfo?.Address_Value}
                            </Typography>                    
                        </IconButton>
                        
                    

                    <Box className="flex__center-c" /* width={"33%"} */ m={"1rem 0"}>
                        <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText">
                            <EmailIcon sx={{fontSize:"1.5rem",mr:"0.5rem",color:"secondary.light"}} />
                            {info?.CompanyInfo?.Email_Value}
                        </Typography>                    
                    </Box>
                    

                        <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText">
                            <LocalPhoneIcon sx={{fontSize:"1.5rem",mr:"0.5rem",color:"secondary.light"}} />
                            {info?.CompanyInfo?.Phone_Value}
                        </Typography>                    

                    
                    
                </Box>

            </Box>

            <Divider flexItem sx={{m:"2rem 0"}}/>


            
            <IconButton sx={{':hover':{backgroundColor:"#ffffff00"}}} href={ info?.CompanyInfo?.Address_Link_Cloudarity || "" } target="_blank" rel="noopener" className="flex__center-r" >
                <Typography variant='subtitle2' color="primary.contrastText" className="flex__center-r" sx={{mr:"1rem"}}>
                    {info?.CompanyInfo?.Designed_By}                    
                </Typography>                                
            </IconButton>
            

        </Box>
    )
}

export default Footer