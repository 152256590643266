import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import useMediaQuery from '@mui/material/useMediaQuery'



import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu'

import { Iinfo } from '../../types/interface'
import { theme } from '../../App'
import { Logo } from '../../components/index'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'

interface Iprops {
    info:Iinfo
}
const style = {
    nav:{
        width:"100%",height:"5rem",backgroundColor:"#ffffffff",zIndex:2,position:"fixed",
        transform:"translateY(0%)",
        transition:"transform 0.5s ease-in-out",
        boxShadow:"rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
    },
    navigationBox:{
        m:"0 0.5rem",
        cursor:"pointer",
        position:" relative",
        display:"block",
        padding:" 4px 0",
        fontFamily:" Lato, sans-serif",
        color:" #242424",
        textDecoration: "none",
        //textTransform:"uppercase",
        transition:" 0.5s",
        ":after":{
            position:" absolute",
            content:"''",
            top:"100%",
            left:"0",
            width:"100%",
            height:"3px",
            backgroundColor:" #3498db",
            transform:"scaleX(0)",
            transformOrigin: "right",
            transition:" transform 0.5s",
        },
        ":hover":{
            color: "#95a5a6"
        },
        ":hover::after":{
            transform: "scaleX(1)",
            transformOrigin: "left"
        }
       
    },
    navigationDropDownBox:{
        height:"100%",
        m:"0 0.5rem",    
       
        
        padding:" 4px 0",
        fontFamily:" Lato, sans-serif",
        color:"#242424",
        /* textDecoration: "none", */
        //textTransform:"uppercase",
        /* transition:"0.2s",       */
        ":hover":{
            color: "#95a5a6"
        },       
       /*  ":hover div":{
            opacity: 1,
            pointerEvents:"auto"
        },    */     
        "div #divider1": {
            transition:"width 0.6s linear",
        },
        ":hover div #divider1": {
            width:"90%"
        }
    },


    boxDropDown: {
        position:"absolute",
        top:"100%",
        width:"fit-content",
        opacity:0,
        pointerEvents:"none",
        left:0,
        transition: "all 0.2s linear",
        borderBottomRightRadius:"2%",
        borderBottomLeftRadius:"2%"
    },
    navigationDropDown:{
        m:"0 0.5rem",
        cursor:"pointer",
        position:" relative",
        display:"block",
        padding:" 4px 0",
        fontFamily:" Lato, sans-serif",
        color:" #242424",
        textDecoration: "none",
        //textTransform:"uppercase",
        transition:" 0.5s",
        ":after":{
            position:" absolute",
            content:"''",
            top:"100%",
            left:"0",
            width:"100%",
            height:"3px",
            backgroundColor:" #3498db",
            transform:"scaleX(0)",
            transformOrigin: "right",
            transition:" transform 0.5s",
        },
        ":hover":{
            color: "#95a5a6",
            
        },
        ":hover::after":{
            transform: "scaleX(1)",
            transformOrigin: "left"
        },
        ":hover div":{
            pointerEvents:"auto",
            opacity:1,        
            backgroundColor:"#ffffffff"
        },
        ":hover button":{
            transform: "translateX(0)"
        }
    },
    
}
    
const Navbar = (props:Iprops) => {
    const { info } = props

    const navigate = useNavigate()
    const mobile = useMediaQuery('(max-width:940px)')
    

    const [scroll, setScroll] = useState(0)
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<string | false>(false);
    const [ dropBoxPointerEvents, setDropBoxPointerEvents ] = useState("none")
    const [ dropBoxOpacity, setDropBoxOpacity ] = useState(0)

    useDisableBodyScroll(openDrawer)
    const buttonDropDown  ={
        //width:"max-content !important",
        "transition": "all 0.2s linear",
        '&:hover': {
            backgroundColor: '#ffffff00',
            
        },
        '&:hover button':{
            //color:"secondary.main"
        },
        "transform": "translateX(-50%)",
        m:"0.5rem",
        cursor:"pointer",
        position:" relative",
        display:"block",
        //padding:" 4px 0",
        //fontFamily:" Lato, sans-serif",
        color:" #242424",
        textDecoration: "none",
        //textTransform:"uppercase",        
        ":after":{
            position:" absolute",
            content:"''",
            top:"100%",
            left:"0",
            width:"100%",
            height:"3px",
            backgroundColor:theme.palette.secondary.main,
            transform:"scaleX(0)",
            transformOrigin: "right",
            transition:" transform 0.5s",
        },
        ":hover":{
            color: "#95a5a6",                
        },
        ":hover::after":{
            transform: "scaleX(1)",
            transformOrigin: "left"
        },
}


    useEffect(() => {
        
        const onScroll = (e:any) => {
           
            //console.log("e2",e.target.defaultView.pageYOffset)
            const pos = e.target.defaultView.pageYOffset
            if(scroll < pos){
                //down
                style["nav"]["transform"] = "translateY(-100%)"
            }else{
                //up
                style["nav"]["transform"] = "translateY(0)"
            }
            setScroll(pos)
            
        };
        window.addEventListener("scroll", onScroll);
      
        return () => window.removeEventListener("scroll",(event)=> onScroll(event));
        
    }, [scroll])


    //* Changes state of navbar/drawer menu when hamburger menu is clicked on => open navbar/ drawer from the left */
    const toggleDrawer = (open: boolean) =>{
        setOpenDrawer(open);
    };
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const MobileNavigate = (link:string) => {
        navigate(link);
        toggleDrawer(false)
    }
    const ChangeDropBoxHover = (dir:string ="") =>{
        if(dir === "hover"){
            setDropBoxPointerEvents("auto")
            setDropBoxOpacity(1)
        }else{
            setDropBoxPointerEvents("none")
            setDropBoxOpacity(0)
        }
    }

    if(mobile){
        return (
        <Box component="nav" sx={style.nav} className='flex' justifyContent="space-between" alignItems="center">
    
            <Typography variant='h1' sx={{ml:"1rem"}}>
                <Logo height={10} width={10} fill={ theme.palette.primary.main } navigateOn={true} />
            </Typography>


            <Button
                color="inherit"                   
                onClick={()=>toggleDrawer(true)}
                sx={{ ...(openDrawer && { display: 'none' }), mr:"1rem" }}
            >
                <MenuIcon />
            </Button>


            <Drawer
                anchor={`right`}
                open={openDrawer}
                onClose={()=>toggleDrawer(false)}
               // sx={{height:"150%"}}
            >
                <Box className="flex__center-r" sx={{justifyContent:"space-around",width:"100%"}}>

                    <Box width={"33%"}></Box>
                    

                    <Typography variant='h3' color="primary" width={"33%"}>
                        <Logo height={6} width={6} fill={ theme.palette.primary.main } navigateOn={true} />
                    </Typography>

                    <IconButton sx={{width:"33%"}} onClick={()=>toggleDrawer(false)}>
                        <ClearIcon />
                    </IconButton>
                    
                </Box>
                <Box >
                    {(info?.Navbar?.Navigation || [])?.map((item, i) => {
                        if(item.type === "simple"){
                            return (
                                <ListItem key={`navbarlistitem${item?.title}${i}`} disablePadding sx={{'& :hover':{color:"secondary.main"},borderRight:`5px solid ${theme.palette.primary.main}`}}>
                                    <ListItemButton onClick={()=> MobileNavigate(item?.link)} /* sx={{'& :hover':{color:"secondary.main",borderBottom:"1px solid red"}}} */> 
                                    {/* <ListItemIcon>
                                        {obj?.icon}
                                    </ListItemIcon> */}
                                        <ListItemText primary={item?.title} color='primary.main'/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }else if(item?.type === "multiple"){
                            return (
                                <ListItem key={`navbarlistitem${item?.title}${i}`} disablePadding sx={{borderRight:`5px solid ${theme.palette.primary.light}`}}>
                                    
                                        <Accordion elevation={0} expanded={expanded === `${item?.title}${i}`} onChange={handleChange(`${item?.title}${i}`)} disableGutters={true}>
                                            <AccordionSummary
                                            sx={{
                                                backgroundColor:'rgba(255, 255, 255, .05)',
                                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                                    transform: 'rotate(90deg)',
                                                },
                                                '& .MuiAccordionSummary-content': {
                                                    marginLeft: "0rem ",
                                                },
                                                '& :hover':{color:"secondary.main"}}}
                                                expandIcon={<ExpandMoreIcon />}                                                    
                                            >
                                            <Typography>
                                                {item?.title}
                                            </Typography>
                                            
                                            </AccordionSummary>
                                            <AccordionDetails >
                                                <Box component="ul" sx={{m:0,p:0}}>
                                                
                                                    {(item?.links || [])?.map((item, i) => {                                                            
                                                        return (
                                                            <ListItem key={`navbarlistitem${item?.title}${i}`} disablePadding sx={{'& :hover':{color:"secondary.main"}}}>
                                                                <ListItemButton onClick={()=> MobileNavigate(item?.link)}>
                                                            
                                                                    <ListItemText primary={item?.title} color='primary.main'/>
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>                                    
                                </ListItem>
                            )
                        }else{
                            return(
                                <ListItem key={`navbarlistitem${item?.title}${i}`} disablePadding sx={{'& :hover':{color:"secondary.main"},borderRight:`5px solid ${theme.palette.primary.main}`}}>
                                    <ListItemButton onClick={()=> MobileNavigate(item?.link)} /* sx={{'& :hover':{color:"secondary.main",borderBottom:"1px solid red"}}} */> 
                                    {/* <ListItemIcon>
                                        {obj?.icon}
                                    </ListItemIcon> */}
                                        <ListItemText primary={item?.title} color='primary.main'/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }                                                                        
                    })}
                </Box>
                
            </Drawer>


        </Box>
        )
    }else{
        return (
            <Box component="nav" sx={style.nav} className='flex__center-r'>

                <Box maxWidth="xl" width="75%" height={"100%"} className="flex__center-r" sx={{justifyContent:"space-between"}}>
        
                    
                        <Logo height={15} width={15} fill={ theme.palette.primary.main } navigateOn={true} />
                    
        
        
                    <Box className="flex__center-r" height="100%" sx={{gap:"0.8rem"}}>

                        {(info?.Navbar?.Navigation || [])?.map((item, i) => {
                            if(item.type === "simple"){
                                return (
                                    <Box key={`navbarlistitemdesktop${item?.title}${i}`}  sx={style?.navigationBox}  onClick={()=> navigate(item?.link)}>
                                        <Typography variant='subtitle1'>{item?.title}</Typography>
                                    </Box>
                                )
                            }
                            else if(item?.type === "multiple"){
                                return (
                                    
                                        <Box className="flex__center-c" key={`navbarlistitemdesktop${item?.title}${i}`}  sx={style?.navigationDropDownBox} onMouseOver={()=>ChangeDropBoxHover("hover")} onMouseLeave={()=>ChangeDropBoxHover()} >
                                            <Typography variant='subtitle1' sx={{cursor:'pointer'}} onClick={()=> navigate(item?.link)}>{item?.title}</Typography>

                                            <Box width={"100%"} className="flex__center-c" onMouseOver={()=>ChangeDropBoxHover("hover")} onMouseLeave={()=>ChangeDropBoxHover()} sx={{ cursor:'auto',backgroundColor: theme.palette.primary.main,minHeight:"10rem",width:"100%",position:"absolute",top:"100%",left:0,right:0,zIndex:-1,opacity: dropBoxOpacity,transition:"opacity 0.2s linear",pointerEvents:dropBoxPointerEvents}}>
                                                <Typography variant='h3' color="primary.contrastText" mt="0.5rem">{item?.title} </Typography>

                                                <Box id="divider1" sx={{m:"1rem 0",width:"0",height:"0.1rem",backgroundColor: theme.palette.secondary.light}}/>


                                                <Box className="flex__center-c" width="100%" justifyContent={"flex-start"} alignItems={"flex-start"} maxHeight={"17rem"} maxWidth="xl" sx={{flexWrap:"wrap"}}>
                                                    {(item?.links || [])?.map((item, i) => {
                                                        return (
                                                            <Button
                                                                key={`dropdownbuttons${i}`}
                                                                onClick={()=> {navigate(item?.link);ChangeDropBoxHover()} }
                                                                sx={{
                                                                    transition:"transform 0.2s linear",
                                                                    ':hover':{ 
                                                                        backgroundColor: "#ffffff00",
                                                                        boxShadow:"none",
                                                                        color: theme.palette.secondary.light,
                                                                        transform:"translateX(0.5rem)",
                                                                        

                                                                    },
                                                                    m:"0.5rem 1rem"
                                                                }}
                                                                >
                                                                <Typography color="primary.contrastText" sx={{textTransform:"capitalize"}} className="flex__center-r" >{item.title}</Typography>                                   
                                                            </Button>
                                                        )
                                                    })}

                                                </Box>

                                            </Box>
                                            

                                        </Box>

                                    
                                    
                                )
                            }else{
                                return (
                                    <Box key={`navbarlistitemdesktop${item?.title}${i}`}  sx={style?.navigationDropDown}  onClick={()=> navigate(item?.link)}>
                                        <Typography variant='subtitle1'>{item?.title}</Typography>

                                        <Box sx={style?.boxDropDown} >                                                                                    
                                            {(item?.links || [])?.map((item, i) => {                                                            
                                                return (
                                                    <Button sx={buttonDropDown} 
                                                        key={`dropdownbuttons${i}`}
                                                        onClick={()=>navigate(item?.link)}                                                     
                                                        >
                                                        <Typography   sx={ {textAlign:"left"} }  >{item.title}</Typography>                                   
                                                    </Button>
                                                )
                                            })}
                                        </Box>

                                    </Box>
                                )
                                
                            }                                                                        
                        })}

                    </Box>

                </Box>

                             
    
            </Box>
        )
    }
    
}

export default Navbar